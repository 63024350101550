function quantityExponent(val) {
    if (val === 0) {
        return 0
    }
    let exp = Math.floor(Math.log(val) / Math.LN10)
    if (val / Math.pow(10, exp) >= 10) {
        exp++
    }
    return exp
}
export function nice(val) {
    const exponent = quantityExponent(val)
    const exp10 = Math.pow(10, exponent)
    const f = val / exp10
    let nf
    if (f < 1.5) {
        nf = 1
    } else if (f < 2.5) {
        nf = 2
    } else if (f < 4) {
        nf = 3
    } else if (f < 7) {
        nf = 5
    } else {
        nf = 10
    }
    val = nf * exp10
    return val
}