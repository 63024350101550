import { default as MuiFormControl } from "@mui/material/FormControl"
import MuiSelect from "@mui/material/Select"
import { styled } from "@mui/material/styles"

export const FormControl = styled(MuiFormControl)({
    width: "fit-content",
    height: "100%",
})

export const Select = styled(MuiSelect)(({ theme }) => ({
    borderRadius: theme.spacing(3),
    color: theme.palette["texts/text-primary"].main,
    width: "100%",
}))