import {
    List as UiList,
    styled,
} from "@mui/material"

import { CTAButton } from "../CTAButton"

export const Container = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
}))

export const List = styled(UiList)(({ theme }) => ({
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(5),
    flex: "1 1 auto",
    overflowY: "auto",
}))

export const ButtonsContainer = styled("div")(() => ({
    marginTop: "auto",
    display: "flex",
    justifyContent: "right",
}))

export const CancelButton = styled(CTAButton)(() => ({
    width: "fit-content",
}))