import { useTheme } from "@emotion/react"
import { InputLabel } from "@mui/material"
import MuiFormControl from "@mui/material/FormControl"

import * as Styled from "./Select.styled"

export const Select = (props) => {
    const {
        children,
        label,
        value,
        onChange,
        onOpen,
        IconComponent,
        MenuProps,
        ...formProps
    } = props

    const theme = useTheme()

    const defaultMenuProps = {
        slotProps: {
            paper: {
                elevation: 2,
                sx: {
                    borderRadius: theme.spacing(3),
                    marginTop: theme.spacing(1),
                },
            },
        },
    }

    return (
        <Styled.FormControl {...formProps}>
            <InputLabel>{label}</InputLabel>
            <Styled.Select
                labelId="select-label"
                label={label}
                IconComponent={IconComponent}
                MenuProps={{
                    ...defaultMenuProps,
                    ...MenuProps,
                }}
                value={value}
                onChange={onChange}
                onOpen={onOpen}>
                {children}
            </Styled.Select>
        </Styled.FormControl>
    )
}

Select.propTypes = MuiFormControl.propTypes