import { RoadDetailInfo as L2RSegmentDetailInfo } from "@l2r-front/l2r-networks"
import { Backdrop as MuiBackdrop, styled, styledForMobile } from "@l2r-front/l2r-ui"

import { HorizontalSignFilters as L2RHorizontalSignFilters } from "../HorizontalSignFilters"


export const Container = styled("div")(({ theme }) => ({
    padding: theme.spacing(5, 6, 0, 6),
    height: "100%",
    overflowY: "scroll",
}))

export const RoadDetailInfo = styled(L2RSegmentDetailInfo)(({ theme }) => ({
    marginBottom: theme.spacing(6),
}))

export const HorizontalSignFilters = styledForMobile(L2RHorizontalSignFilters)(({ theme }) => ({
    backgroundColor: theme.palette["surfaces/surface-primary"].main,
    boxShadow: theme.shadows[27],
    left: 0,
    position: "absolute",
    right: 0,
    zIndex: theme.zIndex.appBar + 1,
}))


export const BackdropFilter = styledForMobile(MuiBackdrop)(({ theme }) => ({
    cursor: "pointer",
    height: "100%",
    position: "absolute",
    width: "100%",
    zIndex: theme.zIndex.mobileStepper,
}))