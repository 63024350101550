import {
    IconButton,
    MobileModulePage as L2RTabs,
    Skeleton as UiSkeleton,
    styledForMobile,
    Tab as UiTab, styled,
    Typography as UiTypography,
} from "@l2r-front/l2r-ui"

export const Skeleton = styled(UiSkeleton)({
    height: 24,
    transform: "scale(1)",
    width: 220,
})

export const TabSkeleton = styled(UiSkeleton)(({ theme }) => ({
    height: "100%",
    marginTop: theme.spacing(5),
    transform: "scale(1)",
    width: "100%",
}))

export const RoadDetailWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 8, 8, 8),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
}))

export const Wrapper = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette["colors/gris/150"].main,
    display: "flex",
    flex: 1,
    flexDirection: "column",
    height: "fit-content",
    padding: theme.spacing(8),
}))

export const Container = styledForMobile("div")(({ isMobile }) => ({
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: !isMobile && "auto",
}))

export const Icon = styled("img")({
    height: 32,
    margin: 0,
    padding: 0,
    width: 32,
})

export const Tab = styled(UiTab)(({ theme }) => ({
    backgroundColor: "transparent",
    borderRadius: "50%",
    flex: "none",
    fontSize: 0,
    height: "fit-content",
    margin: 0,
    padding: `calc(${theme.spacing(1)} + 2px)`,
    width: "fit-content",

    "&.Mui-selected": {
        backgroundColor: "white",
        border: `2px solid ${theme.palette["cta-bg/cta-bg-primary"].main}`,
        padding: theme.spacing(1),
    },
}))

export const IconWrapper = styled("div")(({ color, theme }) => ({
    backgroundColor: theme.palette[color].main,
    borderRadius: "50%",
    padding: theme.spacing(2),
}))

export const Tabs = styled(L2RTabs)(({ theme }) => ({
    marginTop: theme.spacing(5),
    gap: theme.spacing(3),
}))

export const ErrorText = styled(UiTypography)(({ theme }) => ({
    color: theme.palette["texts/text-error"].main,
    marginTop: theme.spacing(5),
}))

export const LocationHeader = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
})

export const FilledIconButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette["cta-bg/cta-bg-primary"].main,
    color: "white",
    borderRadius: theme.spacing(3),
    marginLeft: theme.spacing(1),

    "&:hover": {
        backgroundColor: theme.palette["cta-bg/cta-bg-primary"].dark,
    },
}))