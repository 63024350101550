import { styled, Typography } from "@l2r-front/l2r-ui"

import { STATUS_TODO } from "../../constants/status"

export const QuarterBudgetWrapper = styled("div")(({ status, theme, width }) => ({
    display: status === STATUS_TODO && "grid",
    height: "100%",
    minWidth: "fit-content",
    width: `calc(${width}% - ${theme.spacing(2)})`,
    textAlign: "center",
}))

export const QuarterBudget = styled("div")(({ backgroundColor, status, theme }) => ({
    alignSelf: status === STATUS_TODO && "flex-end",
    backgroundColor: theme.palette[backgroundColor].main,
    borderRadius: theme.spacing(2),
    height: "fit-content",
    padding: theme.spacing(1, 0),
    width: "100%",
}))

export const Text = styled(Typography)(({ textColor, theme }) => ({
    color: textColor,
    padding: theme.spacing(0, 2),
}))

export const Label = styled(Typography)(() => ({
}))