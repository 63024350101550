import {
    IconButton as UiIconButton,
    ListItemButton as UiListItemButton,
    Skeleton as UiSkeleton,
    styled,
    Typography as UiTypography,
    List as UiList,
} from "@mui/material"

import { KeyboardArrowDownIcon, KeyboardArrowUpIcon } from "@l2r-front/l2r-icons"

const ICON_SIZE = 36

export const ListItemButton = styled(UiListItemButton, {
    shouldForwardProp: (prop) => prop !== "highlighted" && prop !== "selected",
})(({ disabled, highlighted, selected, theme }) => {
    const border = selected ? `2px solid ${theme.palette["border/border-primary"].main}`
        : highlighted ? `2px solid ${theme.palette["objects/object-grey"].main}`
            : `1px solid ${theme.palette["border/border-disabled"].main}`

    const horizontalPadding = `calc(${!selected && !highlighted ? theme.spacing(3) + " + 1px" : theme.spacing(3)})`
    const verticalPadding = `calc(${!selected && !highlighted ? theme.spacing(3) + " + 1px" : theme.spacing(3)})`

    return ({
        backgroundColor: `${theme.palette["surfaces/surface-primary"].main} !important`,
        border: border,
        borderRadius: 12,
        color: disabled && theme.palette["texts/text-disabled"].main,
        display: "flex",
        justifyContent: "space-between",
        height: 46,
        marginBottom: theme.spacing(3),
        padding: `${verticalPadding} ${horizontalPadding} ${verticalPadding} ${horizontalPadding}`,
        width: "100%",
        boxSizing: "border-box",
        "&:hover": {
            backgroundColor: `${theme.palette.action.hover} !important`,
        },
    })
})

export const SideContainer = styled("div")(() => ({
    display: "flex",
    alignItems: "center",
}))

export const Image = styled("img")(({ theme }) => ({
    marginRight: theme.spacing(6),
    marginTop: -6,
    marginBottom: -6,
    height: ICON_SIZE,
    width: ICON_SIZE,
    objectFit: "contain",
}))

export const Count = styled(UiTypography)({
    marginLeft: "auto",
})

export const IconButton = styled(UiIconButton)(({ theme }) => ({
    marginLeft: theme.spacing(3),
}))

export const Skeleton = styled(UiSkeleton)(({ theme }) => ({
    height: 48,
    marginBottom: theme.spacing(2),
    transform: "scale(1)",
    width: "100%",
}))

export const ArrowDownIcon = styled(KeyboardArrowDownIcon)(() => ({
}))

export const ArrowUpIcon = styled(KeyboardArrowUpIcon)(() => ({
}))

export const List = styled(UiList)(({ theme }) => ({
    marginLeft: theme.spacing(6),
}))