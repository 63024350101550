import { useCallback } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { useVerticalSignUpdate } from "../../hooks/mutations/useVerticalSignUpdate"
import { useVerticalSign } from "../../hooks/queries/verticalSigning/useVerticalSign"
import { VerticalSignDetailsForm } from "./VerticalSignDetailsForm"
import * as Styled from "./VerticalSignDetailsForm.styled"

export const VerticalSignDetailsFormPost = (props) => {

    const {
        className,
        close,
        signId,
        poleId,
        ...otherProps
    } = props

    const { t } = useTranslation(I18N_NAMESPACE)

    const { data: verticalSign, isLoading, isRefetching, isError } = useVerticalSign(signId)
    const mutation = useVerticalSignUpdate(signId, poleId, {}, {
        errorSnackbarMessage: t(I18N_NAMESPACE, "containers.verticalSignDetailsForm.snackbar.error"),
        successSnackbarMessage: t(I18N_NAMESPACE, "containers.verticalSignDetailsForm.snackbar.success"),
    })

    const onSubmit = useCallback((values) => {
        mutation.mutate(values)
        close(false)
    }, [close, mutation])

    if (isLoading || isRefetching) {
        return <Styled.FormSkeleton className={className} />
    }

    if (isError) {
        return <Styled.ErrorMessage>{t(I18N_NAMESPACE, "containers.verticalSignDetailsForm.error")}</Styled.ErrorMessage>
    }

    return <VerticalSignDetailsForm
        className={className}
        {...otherProps}
        initialValues={verticalSign}
        onSubmit={onSubmit}
        onCancel={close}
    />
}

VerticalSignDetailsFormPost.propTypes = {
    className: PropTypes.string,
    close: PropTypes.func.isRequired,
    signId: PropTypes.string.isRequired,
    poleId: PropTypes.string.isRequired,
}