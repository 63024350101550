import { styled, Typography } from "@l2r-front/l2r-ui"

const DONE_TICK_SIZE = 12

export const ScaleWrapper = styled("div")(({ theme }) => ({
    borderTop: `1px solid ${theme.palette["texts/text-primary"].main}`,
    marginTop: theme.spacing(5),
    paddingBottom: theme.spacing(11),
    position: "relative",
    margin: theme.spacing(5, 8, 0),
}))

export const Tick = styled("div")(({ left, theme }) => ({
    backgroundColor: theme.palette["texts/text-primary"].main,
    height: 4,
    left: `${left}%`,
    position: "absolute",
    transform: "translateX(-50%)",
    width: 1,
}))

export const TickLabel = styled(Typography)(({ left, theme }) => ({
    color: theme.palette["texts/text-primary"].main,
    left: `${left}%`,
    position: "absolute",
    top: theme.spacing(1),
    transform: "translateX(-50%)",
}))

export const DoneText = styled(Typography)(({ theme }) => ({
    color: theme.palette["texts/text-invert"].main,
}))

export const DoneLabelWrapper = styled("div")(({ left, theme }) => ({
    backgroundColor: theme.palette["objects/object-black"].main,
    borderRadius: theme.spacing(2),
    left: `min(max(${left}%, ${DONE_TICK_SIZE/2}px), calc(100% - ${DONE_TICK_SIZE/2}px))`,
    padding: theme.spacing(1, 2),
    position: "absolute",
    top: theme.spacing(2),
    transform: "translateX(-50%)",
}))

export const DoneTick = styled("div")(({ left, theme }) => ({
    backgroundColor: theme.palette["objects/object-black"].main,
    borderRadius: "50%",
    height: DONE_TICK_SIZE,
    left: `min(max(${left}%, ${DONE_TICK_SIZE/2}px), calc(100% - ${DONE_TICK_SIZE/2}px))`,
    position: "absolute",
    top: 0,
    transform: "translateX(-50%) translateY(-50%)",
    width: DONE_TICK_SIZE,
}))
