import { handleRoundNumber } from "./handleRoundNumber"

export const formatMetersToDistance = (value) => {
    if (value < 1000) {
        const valueInMeters = handleRoundNumber(value, 0)
        return `${valueInMeters} m`
    } else {
        return `${handleRoundNumber(value / 1000, 2)} km`
    }
}

export const formatMetersToKilometers = (value) => {
    return `${Math.ceil((value / 1000))} km`
}