import { Autocomplete as MuiAutocomplete, TextField as MuiTextField, styled } from "@mui/material"

export const Autocomplete = styled(MuiAutocomplete)(() => ({
    "* > .MuiAutocomplete-endAdornment": {
        top: "unset",
        bottom: 12,
    },
}))

export const TextField = styled(MuiTextField)(({ theme }) => ({
    "& > .MuiOutlinedInput-root": {
        borderRadius: theme.spacing(3),
    },
}))