import { useCallback } from "react"

import { useVerticalSignTypes } from "../queries/verticalSigning/useVerticalSignTypes"

function findCodeIndex(code, catalog, depth) {
    const codeIndex = catalog.findIndex(item => item.code === code)

    if (codeIndex >= 0) {
        return depth
    } else {
        for (let item of catalog) {
            const codeDepth = findCodeIndex(code, item.children, depth + 1)
            if (codeDepth >= 0) {
                return codeDepth
            }
        }

        return -1
    }
}

export function findVerticalSignTypeByCode(code, catalog) {
    const verticalSignType = catalog.find(item => item.code === code)

    if (verticalSignType) {
        return verticalSignType
    } else {
        for (let item of catalog) {
            const type = findVerticalSignTypeByCode(code, item.children)
            if (type) {
                return type
            }
        }

        return null
    }
}

export function getFinalVerticalSignTypes(catalog) {
    
    const getLeafTypes = (types) => {
        let leafTypes = []

        types.forEach(type => {
            if (!type.children?.length) {
                leafTypes.push(type)
            } else {
                leafTypes = leafTypes.concat(getLeafTypes(type.children))
            }
        })

        return leafTypes
    }

    return getLeafTypes(catalog)
}

export function useGetVerticalSignTypeCatalogUtils() {
    const { data: verticalSignTypes } = useVerticalSignTypes()

    const getVerticalSignTypeDepth = useCallback((code) => {
        if (!verticalSignTypes) {
            return -1
        }

        return findCodeIndex(code, verticalSignTypes, 0)
    }, [verticalSignTypes])

    const getVerticalSignTypeChildren = useCallback((code) => {
        if (!verticalSignTypes) {
            return []
        }

        const verticalSignType = findVerticalSignTypeByCode(code, verticalSignTypes)
        return verticalSignType ? verticalSignType.children : []
    }, [verticalSignTypes])

    return {
        getVerticalSignTypeDepth,
        getVerticalSignTypeChildren,
    }
}