import { useCallback, useEffect, useMemo, useState } from "react"

import { Formik } from "formik"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { ArrowRightIcon } from "@l2r-front/l2r-icons"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { CTAButton, MenuItem, TagsAutocomplete } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { VERTICAL_SIGNING_CONDITIONS, VERTICAL_SIGNING_CONDITIONS_VALUES } from "../../constants/verticalSigningConditions"
import { useVerticalSigningContext } from "../../containers/VerticalSignDetailsEditModal"
import { useVerticalSigningProject } from "../../hooks/queries/verticalSigning/useVerticalSigningProject"
import { useVerticalSignTypes } from "../../hooks/queries/verticalSigning/useVerticalSignTypes"
import { findVerticalSignTypeByCode, getFinalVerticalSignTypes } from "../../hooks/utils/useGetVerticalSignTypeCatalogUtils"
import * as Styled from "./VerticalSignDetailsForm.styled"

const CONTENT_MAX_CHARACTERS = 100

export const VerticalSignDetailsForm = (props) => {

    const {
        initialValues,
        className,
        onCancel,
        onChange,
        onSubmit,
    } = props

    const [parentType, setParentType] = useState(null)
    const [contextState, contextDispatch] = useVerticalSigningContext()
    const { typeSelectionDisplayed } = contextState
    const { setTypeSelectionDisplayed, setOnBack } = contextDispatch
    const { t } = useTranslation(I18N_NAMESPACE)

    const { data: project } = useVerticalSigningProject()
    const { data: verticalSignTypes } = useVerticalSignTypes()

    const existingTags = useMemo(() => {
        if (project?.tags) {
            return project.tags.sort()
        }

        return []
    }, [project])

    const conditionOptions = useMemo(() => {
        return VERTICAL_SIGNING_CONDITIONS.filter(condition => condition > 0)
            .map(condition => <MenuItem
                key={condition}
                value={condition}>
                {t(I18N_NAMESPACE, VERTICAL_SIGNING_CONDITIONS_VALUES[condition].label)}
            </MenuItem>)
    }, [t])

    const verticalSignTypeOptions = useMemo(() => {
        const finalVerticalTypes = getFinalVerticalSignTypes(verticalSignTypes || [])
        return finalVerticalTypes.map(type => <MenuItem
            key={type.code}
            value={type.code}>
            {type.name}
        </MenuItem>)
    }, [verticalSignTypes])

    const displayedVerticalSignTypes = useMemo(() => {
        const types = !parentType ? verticalSignTypes : parentType.children

        const mapItem = (item => ({
            icon: item.icon,
            label: item.name,
            value: item.code,
            children: item.children.map(child => mapItem(child)),
        }))

        return types?.map(type => mapItem(type))
    }, [parentType, verticalSignTypes])

    const verticalSignTypesListTitle = useMemo(() => (
        parentType ? parentType.name : t(I18N_NAMESPACE, "containers.verticalSignDetailsForm.category")
    ), [parentType, t])

    const compareValues = useCallback((values, initialValues) => {
        const { tags: initialTags, ...initialFields } = initialValues
        const { tags, ...fields } = values

        const areInitialTags = (tags?.length === initialTags.length) && tags.every(tag => initialTags.includes(tag))
        const areInitialFields = Object.keys(fields).every(key => fields[key] === initialFields[key])

        return areInitialTags && areInitialFields
    }, [])

    const handleFieldChange = useCallback((fieldName, value, values) => {
        const newValues = {
            ...values,
            [fieldName]: value,
        }

        const areInitialValues = compareValues(newValues, initialValues)
        onChange(!areInitialValues)
    }, [initialValues, compareValues, onChange])

    const handleOpenTypeSelection = useCallback(() => {
        setTypeSelectionDisplayed(true)
    }, [setTypeSelectionDisplayed])

    const handleTypeHierarchyClick = useCallback((code, setFieldValue, values) => {
        const verticalSignType = findVerticalSignTypeByCode(code, verticalSignTypes)

        if (verticalSignType?.children?.length) {
            setParentType(verticalSignType)
        } else {
            setFieldValue("vertical_sign_type", code)
            handleFieldChange("vertical_sign_type", code, values)
            setParentType(null)
            setTypeSelectionDisplayed(false)
            if (!verticalSignType.is_textual) {
                setFieldValue("content", "")
            }
        }
    }, [verticalSignTypes,
        setParentType,
        setTypeSelectionDisplayed,
        handleFieldChange,
    ])

    const handleTypeHierarchyCancel = useCallback(() => {
        setParentType(null)
        setTypeSelectionDisplayed(false)
    }, [setParentType, setTypeSelectionDisplayed])

    const handleBack = useCallback(() => {
        if (parentType) {
            const parentVerticalSignType = findVerticalSignTypeByCode(parentType.parent, verticalSignTypes)
            setParentType(parentVerticalSignType)
        } else {
            setTypeSelectionDisplayed(false)
        }
    }, [parentType,
        verticalSignTypes,
        setParentType,
        setTypeSelectionDisplayed,
    ])

    useEffect(() => {
        setOnBack(handleBack)

        return () => setOnBack(null)
    }, [handleBack, setOnBack])

    return <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
    >
        {({ values, setFieldValue }) => {
            const areInitialValues = compareValues(values, initialValues)

            const verticalSignType = findVerticalSignTypeByCode(values.vertical_sign_type, verticalSignTypes || [])
            const isTextual = verticalSignType ? verticalSignType.is_textual : false

            return !typeSelectionDisplayed ? <Styled.Form className={className}>
                <Styled.Select
                    id="condition-select"
                    name={"condition"}
                    value={values.condition ?? ""}
                    label={t(I18N_NAMESPACE, "containers.verticalSignDetailsForm.condition")}
                    onChange={v => handleFieldChange("condition", v, values)}>
                    {conditionOptions}
                </Styled.Select>
                <Styled.Select
                    id="type-select"
                    IconComponent={ArrowRightIcon}
                    name={"vertical_sign_type"}
                    value={values.vertical_sign_type ?? ""}
                    label={t(I18N_NAMESPACE, "containers.verticalSignDetailsForm.type")}
                    onOpen={handleOpenTypeSelection}>
                    {verticalSignTypeOptions}
                </Styled.Select>
                {isTextual && <Styled.TextField
                    id="content-text"
                    name="content"
                    type="text"
                    value={values.content ?? ""}
                    inputProps={{ maxLength: CONTENT_MAX_CHARACTERS }}
                    label={t(I18N_NAMESPACE, "containers.verticalSignDetailsForm.panelText")}
                    labelOutside={false}
                    onChange={e => handleFieldChange("content", e.target.value, values)} />}
                <TagsAutocomplete
                    name="tags"
                    variant="outlined"
                    tags={values.tags}
                    existingTags={existingTags}
                    onChange={v => handleFieldChange("tags", v, values)} />
                <Styled.ButtonsWrapper>
                    <CTAButton
                        id="cancel-button"
                        onClick={() => {
                            onCancel()
                        }}
                        variant="outlined"
                    >
                        {t(I18N_NAMESPACE, "containers.verticalSignDetailsForm.cancel")}
                    </CTAButton>
                    <Styled.SubmitButton
                        id="submit-button"
                        color="cta-bg/cta-bg-primary"
                        type="submit"
                        variant="contained"
                        disabled={areInitialValues}
                    >
                        {t(I18N_NAMESPACE, "containers.verticalSignDetailsForm.save")}
                    </Styled.SubmitButton>
                </Styled.ButtonsWrapper>
            </Styled.Form>
                : <Styled.HierarchicalList
                    data={displayedVerticalSignTypes}
                    title={verticalSignTypesListTitle}
                    onCancel={handleTypeHierarchyCancel}
                    onClick={(code) => handleTypeHierarchyClick(code, setFieldValue, values)} />
        }}
    </Formik>
}

VerticalSignDetailsForm.propTypes = {
    signTags: PropTypes.arrayOf(PropTypes.string),
    className: PropTypes.string,
    onCancel: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
}