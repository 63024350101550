import { Typography } from "@mui/material"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import * as Styled from "./HierarchicalListItem.styled"

export const HierarchicalListItem = (props) => {
    const {
        defaultIcon,
        icon,
        label,
        value,
        children,
        onClick,
    } = props

    return <Styled.ListItemButton onClick={() => onClick(value)}>
        {icon && <Styled.Image
            src={icon}
            onError={e => e.target.src = defaultIcon}
        />}
        <Typography variant={"Regular"}>{label}</Typography>
        {!!children?.length && <Styled.ArrowIcon color="objects/object-placeholder" />}
    </Styled.ListItemButton >
}

HierarchicalListItem.propTypes = {
    defaultIcon: PropTypes.string,
    icon: PropTypes.string,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.object).isRequired,
    onClick: PropTypes.func.isRequired,
}

