import { useCallback, useMemo, useState } from "react"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import { VerticalSignDetailsEditStateContext, VerticalSignDetailsEditDispatchContext, initialState } from "./VerticalSignDetailsEditContext.context"

export const VerticalSignDetailsEditContextProvider = (props) => {

    const { children } = props

    const [state, setState] = useState(initialState)

    const setTypeSelectionDisplayed = useCallback((typeSelectionDisplayed) => {
        setState((previousState) => ({
            ...previousState,
            typeSelectionDisplayed: typeSelectionDisplayed,
        }))
    }, [])

    const setOnBack = useCallback((onBackCallback) => {
        setState((previousState) => ({
            ...previousState,
            onBack: onBackCallback,
        }))
    }, [])

    const dispatchValue = useMemo(() => {
        return {
            setTypeSelectionDisplayed,
            setOnBack,
        }
    }, [setTypeSelectionDisplayed, setOnBack])

    return (
        <VerticalSignDetailsEditStateContext.Provider value={state}>
            <VerticalSignDetailsEditDispatchContext.Provider value={dispatchValue}>
                {children}
            </VerticalSignDetailsEditDispatchContext.Provider>
        </VerticalSignDetailsEditStateContext.Provider>
    )
}

VerticalSignDetailsEditContextProvider.propTypes = {
    children: PropTypes.node,
}
