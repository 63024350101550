import { useCallback } from "react"

import { useVerticalSigningDispatchContext, useVerticalSigningStateContext } from "../../contexts/VerticalSigningContext/VerticalSigningContext.hooks"
import { VerticalSignFiltersForm } from "./VerticalSignFiltersForm"

export function VerticalSignFiltersFormPost(props) {
    const {
        close,
        ...verticalSignFiltersFormProps
    } = props

    const { setFilters } = useVerticalSigningDispatchContext()
    const { filters } = useVerticalSigningStateContext()

    const onSubmit = useCallback((values) => {
        setFilters(values)
        close()
    }, [close, setFilters])

    return <VerticalSignFiltersForm
        {...verticalSignFiltersFormProps}
        activeFilters={filters}
        close={close}
        onSubmit={onSubmit}
        resetFilters={() => setFilters({ tags: [] })}
    />
}