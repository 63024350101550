import { useCallback } from "react"

import {
    useMapStateContext,
    BASE_MAP_ID,
} from "@l2r-front/l2r-map"
import { PropTypes } from "@l2r-front/l2r-proptypes"

import { useNetworksStateContext } from "../../contexts/NetworksContext"
import { BaseNetworkReferentialLayer } from "../BaseNetworkReferentialLayer"

export function NetworkReferentialLayer(props) {
    const {
        onClickOnFeature,
        mapId,
        ...layerProps
    } = props

    const { selectedLinearLocations } = useNetworksStateContext()

    const { currentMapStyle } = useMapStateContext()

    const handleLayerClick = useCallback(async (feature) => {
        if (onClickOnFeature) {
            onClickOnFeature(feature)
        }
    }, [onClickOnFeature])

    return (
        <BaseNetworkReferentialLayer
            onClickOnFeature={handleLayerClick}
            mapId ={mapId}
            mapStyle={currentMapStyle}
            selectedRoad={selectedLinearLocations?.[0]?.road || null}
            {...layerProps}
        />
    )
}

NetworkReferentialLayer.propTypes = {
    onClickOnFeature: PropTypes.func,
    mapId: PropTypes.string,
}

NetworkReferentialLayer.defaultProps = {
    mapId: BASE_MAP_ID,
}