import { useCallback, useMemo } from "react"

import * as turf from "@turf/turf"

import { sanitizeData } from "@l2r-front/l2r-geodata"
import { useTranslation } from "@l2r-front/l2r-i18n"
import { useNetworkReferential } from "@l2r-front/l2r-networks"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useNavigateWithSearchParams } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { useVerticalSignPoleUpdate } from "../../hooks/mutations/useVerticalSignPoleUpdate"
import { VerticalSignPoleEditForm } from "./VerticalSignPoleEditForm"

export const VerticalSignPoleEditFormPost = (props) => {

    const {
        close,
        poleId,
        startingPosition,
        startingRoad,
        ...otherProps
    } = props

    const { t } = useTranslation(I18N_NAMESPACE)
    const navigate = useNavigateWithSearchParams()

    const { data: referential } = useNetworkReferential()
    const sanitizedReferential = useMemo(() => {
        return sanitizeData(referential)
    }, [referential])

    const mutation = useVerticalSignPoleUpdate(poleId, {}, {
        onSuccess: (data) => navigate(`../${data.linear_location.road}/${data.uuid}`),
        errorSnackbarMessage: t(I18N_NAMESPACE, "containers.verticalSignPoleLocationEditForm.snackbar.error"),
        successSnackbarMessage: t(I18N_NAMESPACE, "containers.verticalSignPoleLocationEditForm.snackbar.success"),
    }) 

    const onSubmit = useCallback(values => {
        const linearLocation = {
            typeid: 0,
            road: values.road.road,
            start: values.road.start,
            end: values.road.start + 1,
        }
        const point = turf.point([values.position.longitude, values.position.latitude])

        mutation.mutate({linear_location: linearLocation, geometry: point.geometry})

        close()
    }, [close, mutation])

    return <VerticalSignPoleEditForm
        {...otherProps}
        referential={sanitizedReferential}
        startingPosition={startingPosition}
        startingRoad={startingRoad}
        onSubmit={onSubmit}
        onCancel={close}
    />
}

VerticalSignPoleEditFormPost.propTypes = {
    close: PropTypes.func.isRequired,
    poleId: PropTypes.string.isRequired,
    startingPosition: PropTypes.shape({
        longitude: PropTypes.number,
        latitude: PropTypes.number,
    }).isRequired,
    startingRoad: PropTypes.string,
}