import { useMemo } from "react"

import { Collapse, Typography } from "@mui/material"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import * as Styled from "./CatalogListItem.styled"

export function CatalogListItem(props) {

    const {
        children,
        defaultIcon,
        disabled,
        expandable,
        highlighted,
        icon,
        label,
        onClick,
        selected,
        ItemsCountComponent,
    } = props

    const isOpen = highlighted || selected
    const displayChildren = !!(expandable && isOpen && children)
    const displayArrowIcon = !disabled && expandable

    const arrowIcon = useMemo(() => {
        return isOpen ? <Styled.ArrowUpIcon color="objects/object-placeholder" />
            : <Styled.ArrowDownIcon color="objects/object-placeholder" />
    }, [isOpen])

    return <>
        <Styled.ListItemButton key={Math.random()}
            disabled={disabled}
            highlighted={highlighted}
            onClick={(disabled || !onClick) ? undefined : onClick}
            selected={selected}
        >
            <Styled.SideContainer>
                {icon && <Styled.Image
                    src={icon}
                    onError={e => e.target.src = defaultIcon}
                />}
                <Typography variant={(expandable && !disabled || selected || highlighted) ? "H3" : "Regular"}>{label}</Typography>
            </Styled.SideContainer>
            <Styled.SideContainer>
                {ItemsCountComponent}
                {displayArrowIcon && arrowIcon}
            </Styled.SideContainer>
        </Styled.ListItemButton>
        <Collapse in={displayChildren}>
            <Styled.List disablePadding>
                {children}
            </Styled.List>
        </Collapse>
    </>
}

CatalogListItem.propTypes = {
    children: PropTypes.node,
    code: PropTypes.string,
    defaultIcon: PropTypes.string,
    disabled: PropTypes.bool,
    expandable: PropTypes.bool,
    highlighted: PropTypes.bool,
    icon: PropTypes.string,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    selected: PropTypes.bool,
    startOpen: PropTypes.bool,
    ItemsCountComponent: PropTypes.node,
}