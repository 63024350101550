import { useCallback, useMemo } from "react"

import _ from "lodash"

import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useUrlParams } from "@l2r-front/l2r-utils"

import { HORIZONTAL_SIGNING_CODE_URL_PARAM, HORIZONTAL_SIGNING_CONDITION_URL_PARAM, HORIZONTAL_SIGNING_TAGS_URL_PARAM } from "../../constants/urlParams"
import { useAscendantCodes } from "../../hooks/utils/useAscendantCodes"
import { useDescendantCodes } from "../../hooks/utils/useDescendantCodes"
import { HorizontalSigningStateContext, HorizontalSigningDispatchContext } from "./HorizontalSigningContext.context"

export const HorizontalSigningContextProvider = (props) => {

    const { children } = props

    const { getParam, setParam, deleteParam } = useUrlParams()
    const conditionFilters = getParam(HORIZONTAL_SIGNING_CONDITION_URL_PARAM)
    const typeFilter = getParam(HORIZONTAL_SIGNING_CODE_URL_PARAM)
    const tagsFilter = getParam(HORIZONTAL_SIGNING_TAGS_URL_PARAM)
    const ascendantCodes = useAscendantCodes(typeFilter)
    const descendantCodes = useDescendantCodes(typeFilter)

    const setFilters = useCallback((newFilters) => {
        if (newFilters?.type && typeFilter !== newFilters.type) {
            setParam(HORIZONTAL_SIGNING_CODE_URL_PARAM, newFilters.type)
        } else if (newFilters.type === null) {
            deleteParam(HORIZONTAL_SIGNING_CODE_URL_PARAM)
        }

        if (newFilters?.condition) {
            let conditionFilterArray = conditionFilters?.split(",") || []
            if (conditionFilterArray.includes(newFilters.condition)) {
                const index = conditionFilterArray.findIndex(condition => {
                    return condition === newFilters.condition
                })
                conditionFilterArray.splice(index, 1)
            } else {
                conditionFilterArray.push(newFilters.condition)
            }
            if (conditionFilterArray.length) {
                setParam(HORIZONTAL_SIGNING_CONDITION_URL_PARAM, conditionFilterArray.join(","))
            } else {
                deleteParam(HORIZONTAL_SIGNING_CONDITION_URL_PARAM)
            }
        }
        if (newFilters?.tags) {
            if (newFilters?.tags.length) {
                setParam(HORIZONTAL_SIGNING_TAGS_URL_PARAM, newFilters?.tags.join(","))
            } else {
                deleteParam(HORIZONTAL_SIGNING_TAGS_URL_PARAM)
            }
        }
    }, [conditionFilters, deleteParam, setParam, typeFilter])

    const stateValue = useMemo(() => {
        return {
            ascendantCodes,
            descendantCodes,
            filters: {
                ...(conditionFilters && {
                    conditions: conditionFilters.split(",")?.map(condition => parseInt(condition)),
                }),
                tags: tagsFilter?.split(",") || [],
                type: typeFilter,
            },
        }
    }, [ascendantCodes, descendantCodes, conditionFilters, tagsFilter, typeFilter])

    const dispatchValue = useMemo(() => {
        return {
            setFilters,
        }
    }, [setFilters])

    return (
        <HorizontalSigningStateContext.Provider value={stateValue}>
            <HorizontalSigningDispatchContext.Provider value={dispatchValue}>
                {children}
            </HorizontalSigningDispatchContext.Provider>
        </HorizontalSigningStateContext.Provider>
    )
}

HorizontalSigningContextProvider.propTypes = {
    children: PropTypes.node,
}
