import { useMemo } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { useNetworksStateContext } from "@l2r-front/l2r-networks"
import { Typography, useMediaQuery, theme } from "@l2r-front/l2r-ui"
import { formatMetersToKilometers } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { useGetActiveModules } from "../../../../common/hooks/useGetActiveModules"
import * as Styled from "./Dashboard.styled"

export const Dashboard = () => {
    const { selectedNetwork } = useNetworksStateContext()
    const appsWithActiveModules = useGetActiveModules()
    const { t } = useTranslation(I18N_NAMESPACE)
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"))

    const kpis = useMemo(() => {
        return Object.keys(appsWithActiveModules).reduce((kpisArray, appKey) => {
            for (const moduleKey in appsWithActiveModules[appKey].modules) {
                const module = appsWithActiveModules[appKey].modules[moduleKey]
                if (module.kpis) {
                    kpisArray.push(module.kpis)
                }
            }

            return kpisArray.flat()
        }, [])
    }, [appsWithActiveModules])

    const sidebarKpis = useMemo(() => {
        return kpis
            .sort((a, b) => a.position - b.position)
            .filter(kpi => kpi.parent === "sidebar")
            .map((kpi, index) => {
                const Component = kpi.component
                return <Component key={`kpi-${index}`} style={{ height: "fit-content" }} />
            })
    }, [kpis])

    const listKpis = useMemo(() => {
        if (kpis) {
            if (!isLargeScreen) {
                const mobileDashboard = kpis
                    .sort((a, b) => a.mobileOrder - b.mobileOrder)
                    .map((kpi, index) => {
                        const Component = kpi.component
                        return <Component key={`kpi-${index}`} style={{
                            minHeight: kpi.mobileHeight,
                        }} />
                    })
                return <Styled.Scrollable id="kpi-list">
                    {mobileDashboard}
                </Styled.Scrollable>
            }
            return kpis
                .filter(kpi => kpi.parent === "list")
                .map((kpi, index) => {
                    const Component = kpi.component
                    return <Component key={`kpi-${index}`} style={{
                        gridArea: kpi.grid,
                        height: kpi.height,
                    }} />
                })
        }
    }, [kpis, isLargeScreen])

    return (
        <Styled.PanelContent>
            {isLargeScreen && <Styled.Sidebar>
                <Styled.NetworkName id="network-name" variant="H1">
                    {selectedNetwork ? selectedNetwork.nameLong : ""}
                </Styled.NetworkName>
                <Styled.Wrapper>
                    <Styled.NetworkLength>
                        <Typography variant="H3">
                            {`${t(I18N_NAMESPACE, "components.networkLength.label")}`}
                        </Typography>
                        <Typography variant="H1">
                            {`${formatMetersToKilometers(selectedNetwork.length)}`}
                        </Typography>
                    </Styled.NetworkLength>
                </Styled.Wrapper>
                <Styled.PanelWrapper>
                    {sidebarKpis}
                </Styled.PanelWrapper>
            </Styled.Sidebar>}
            <Styled.KpiContainer isMobile={!isLargeScreen}>
                {!isLargeScreen && <Styled.NetworkName id="network-name" variant="H1">
                    {selectedNetwork ? selectedNetwork.nameLong : ""}
                </Styled.NetworkName>}
                {!isLargeScreen &&  
                <Styled.Wrapper>
                    <Styled.NetworkLength>
                        <Typography variant="H3">
                            {`${t(I18N_NAMESPACE, "components.networkLength.label")}`}
                        </Typography>
                        <Typography variant="H1">
                            {`${formatMetersToKilometers(selectedNetwork.length)}`}
                        </Typography>
                    </Styled.NetworkLength>
                </Styled.Wrapper>
                }
                {kpis.length ? listKpis : (
                    <Typography id="noActiveApplications-text" variant="Small">
                        {t(I18N_NAMESPACE, "pages.messages.noActiveApplications")}
                    </Typography>
                )}
            </Styled.KpiContainer>
        </Styled.PanelContent>
    )
}