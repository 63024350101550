import { useCallback, useMemo } from "react"

import { ChipList } from "@l2r-front/l2r-ui"

import { useVerticalSigningStateContext, useVerticalSigningDispatchContext } from "../../contexts/VerticalSigningContext"

export function TagsFilterList(props) {

    const { filters } = useVerticalSigningStateContext()
    const { setFilters } = useVerticalSigningDispatchContext()

    const displayedFilters = useMemo(() => {
        return filters.tags.map((filterValue, i) => ({
            key: "tags",
            index: i,
            label: filterValue,
            value: filterValue,
        }))
    }, [filters])

    const deleteFilter = useCallback((filterIndex) => {
        const tagsFilters = filters?.tags
        tagsFilters.splice(filterIndex, 1)
        setFilters({
            "tags": tagsFilters,
        })
    }, [filters, setFilters])

    if (filters?.tags?.length) {
        return <ChipList
            {...props}
            onDelete={deleteFilter}
            values={displayedFilters}
            variant="filled"
        />
    }
    return null
}
