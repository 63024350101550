import {
    Button as UiButton,
    FormikAutoCompleteWithChips,
    Skeleton as UiSkeleton,
    styled,
    Typography as UiTypography,
} from "@l2r-front/l2r-ui"

export const TagFilter = styled(FormikAutoCompleteWithChips)(() => ({
    width: "100%",

    "& .MuiAutocomplete-popupIndicator": {
        transform: "none",
    },
}))

export const Button = styled(UiButton)({
    textTransform: "none",
})

export const SubmitButton = styled(UiButton)(({ theme }) => ({
    marginLeft: theme.spacing(2),
    textTransform: "capitalize",
}))

export const ButtonsWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    padding: theme.spacing(4, 0, 0, 0),
}))

export const Wrapper = styled("div")({
    display: "flex",
    marginLeft: "auto",
})

export const InputsWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: theme.spacing(2),
}))

export const FormWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(8, 4),
}))

export const Skeleton = styled(UiSkeleton)(({ theme }) => ({
    height: "172.5px",
    margin: theme.spacing(8, 4),
    transform: "scale(1)",
    width: "auto",
}))

export const Error = styled(UiTypography)(({ theme }) => ({
    color: theme.palette["texts/text-error"].main,
    margin: theme.spacing(8, 4),
    textAlign: "center",
    width: "auto",
}))