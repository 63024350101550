import { useContext } from "react"

import { VerticalSignDetailsEditStateContext, VerticalSignDetailsEditDispatchContext } from "./VerticalSignDetailsEditContext.context"

export const useVerticalSignDetailsEditStateContext = () => {
    const context = useContext(VerticalSignDetailsEditStateContext)
    if (context === undefined) {
        throw new Error("useVerticalSignDetailsEditStateContext must be used within an VerticalSignDetailsEditStateContext")
    }
    return context
}

export const useVerticalSignDetailsEditDispatchContext = () => {
    const context = useContext(VerticalSignDetailsEditDispatchContext)
    if (context === undefined) {
        throw new Error("useVerticalSignDetailsEditDispatchContext must be used within an VerticalSignDetailsEditDispatchContext")
    }
    return context
}

export const useVerticalSigningContext = () => {
    return [useVerticalSignDetailsEditStateContext(), useVerticalSignDetailsEditDispatchContext()]
}
