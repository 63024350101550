import { useCallback, useEffect, useMemo, useState } from "react"

import { useParams } from "react-router-dom"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { FilterListRoundedIcon } from "@l2r-front/l2r-icons"
import { useMapDispatchContext } from "@l2r-front/l2r-map"
import { useNetworksDispatchContext } from "@l2r-front/l2r-networks"
import { SidebarContent, useIsMobileDevice } from "@l2r-front/l2r-ui"
import { useNavigateWithSearchParams } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { VerticalSignsSidebarComponents } from "../../components/VerticalSignsSidebarComponents"
import { useVerticalSigningStateContext } from "../../contexts/VerticalSigningContext"
import { TagsFilterList } from "../TagsFilterList"
import * as Styled from "./VerticalSigningSidebar.styled"

export function VerticalSigningSidebar() {

    const { t } = useTranslation([I18N_NAMESPACE])
    const { ascendantCodes, descendantCodes } = useVerticalSigningStateContext()
    const isMobile = useIsMobileDevice()
    const [displayFilters, setDisplayFilters] = useState(false)

    const toggleFilters = useCallback(() => {
        return setDisplayFilters(previousState => !previousState)
    }, [])
    const { getMapRef } = useMapDispatchContext()
    const { road } = useParams()
    const { setRoadSearched } = useNetworksDispatchContext()
    const navigate = useNavigateWithSearchParams()

    const additionalSidebars = useMemo(() => {
        if (isMobile) {
            return null
        }

        return [...ascendantCodes]
            .reverse()
            .filter((_, index) => ((index % 2 === 1) && ((ascendantCodes.length > index + 1) || descendantCodes.length > 1)))
            .map((code, index) => (
                <Styled.AdditionalSidebar key={code} index={index} >
                    <Styled.VerticalSignTypeList baseVerticalSignTypeCode={code} />
                </Styled.AdditionalSidebar>
            ))
    }, [ascendantCodes, descendantCodes, isMobile])

    const filterComponents = useMemo(() => {
        return (
            <Styled.TitleSideComponentWrapper>
                {!isMobile && <Styled.IconButton onClick={toggleFilters}>
                    <FilterListRoundedIcon color="cta-bg/cta-bg-primary" id="incidents-filters-button" />
                </Styled.IconButton>}
            </Styled.TitleSideComponentWrapper>
        )
    }, [isMobile, toggleFilters])

    const navigateBack = useCallback(() => {
        setRoadSearched(null)
        navigate("..")
    }, [navigate, setRoadSearched])

    useEffect(() => {
        const map = getMapRef()?.getMap()
        if (map?.style) {
            map?.resize()
        }
    }, [ascendantCodes, getMapRef])

    return (
        <SidebarContent
            id="verticalSigning-sidebar"
            onBack={road ? navigateBack : null}
            title={t(I18N_NAMESPACE, `containers.verticalSigningSidebar.${road ? "roadTitle" : "title"}`)}
            titleSideComponent={filterComponents}
        >
            <Styled.TagsFilterListWrapper>
                <TagsFilterList />
            </Styled.TagsFilterListWrapper>
            <Styled.Container>
                <Styled.VerticalSignFilters displayFilters={displayFilters} close={toggleFilters} />
                <VerticalSignsSidebarComponents />
            </Styled.Container>
            {additionalSidebars}
        </SidebarContent>
    )
}