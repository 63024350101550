import { Form as FormikForm } from "formik"

import {
    Button as UiButton,
    HierarchicalList as UIHierarchicalList,
    FormikSelect,
    FormikTextField,
    Skeleton,
    Typography,
    styled,
} from "@l2r-front/l2r-ui"


export const Form = styled(FormikForm)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4),
    width: "100%",
    height: "fit-content",
}))

export const Select = styled(FormikSelect)(() => ({
    width: "100%",
    height: "auto",
}))

export const TextField = styled(FormikTextField)(() => ({
    width: "100%",
}))

export const ButtonsWrapper = styled("div")(({ theme }) => ({
    marginTop: "auto",
    display: "flex",
    justifyContent: "right",
    gap: theme.spacing(5),
}))

export const Button = styled(UiButton)({
    textDecoration: "underline",
    textTransform: "none",
    "&:hover": {
        textDecoration: "underline",
    },
})

export const SubmitButton = styled(UiButton)(({ theme }) => ({
    marginLeft: theme.spacing(2),
    textTransform: "capitalize",
}))

export const HierarchicalList = styled(UIHierarchicalList)({
    flex: "1 1 auto",
    overflowY: "hidden",
})

export const FormSkeleton = styled(Skeleton)(() => ({
    transform: "none",
}))

export const ErrorMessage = styled(Typography)(({ theme }) => ({
    color: theme.palette["error/main"].main,
}))