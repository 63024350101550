import { Form as FormikForm } from "formik"

import {
    Button as UiButton,
    styled,
    styledForMobile,
} from "@l2r-front/l2r-ui"

const DESKTOP_MAP_HEIGHT = 400

export const Form = styled(FormikForm)(() => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
}))

export const MapContainer = styledForMobile("div")(({ theme, isMobile }) => ({
    height: isMobile ? "100%" : DESKTOP_MAP_HEIGHT,
    marginBottom: theme.spacing(8),
}))

export const ButtonsWrapper = styled("div")(({ theme }) => ({
    marginTop: "auto",
    display: "flex",
    justifyContent: "right",
    gap: theme.spacing(5),
}))

export const Button = styled(UiButton)({
    textDecoration: "underline",
    textTransform: "none",
    "&:hover": {
        textDecoration: "underline",
    },
})

export const SubmitButton = styled(UiButton)(({ theme }) => ({
    marginLeft: theme.spacing(2),
    textTransform: "capitalize",
}))