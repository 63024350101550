import { useCallback, useState } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { ArrowBackIcon } from "@l2r-front/l2r-icons"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useAlertsDispatchContext } from "@l2r-front/l2r-query"
import { Modal, useIsMobileDevice } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { useVerticalSignDetailsEditStateContext } from "./VerticalSignDetailsEditContext.hooks"
import { VerticalSignDetailsEditContextProvider } from "./VerticalSignDetailsEditContext.provider"
import * as Styled from "./VerticalSignDetailsEditModal.styled"

export const VerticalSignDetailsEditModal = (props) => {

    const {
        className,
        open,
        onClose,
        ...otherProps
    } = props

    const [hasOngoingModifications, setHasOngoingModifications] = useState(false)

    const { setAlert } = useAlertsDispatchContext()
    const { t } = useTranslation(I18N_NAMESPACE)

    const handleClose = useCallback((checkModifications = true) => {
        if (checkModifications && hasOngoingModifications) {
            setAlert({
                alertTitle: t(I18N_NAMESPACE, "containers.verticalSignDetailsForm.alert.title"),
                cancelText: t(I18N_NAMESPACE, "containers.verticalSignDetailsForm.alert.cancelButton"),
                confirmText: t(I18N_NAMESPACE, "containers.verticalSignDetailsForm.alert.confirmButton"),
                callback: () => {
                    setHasOngoingModifications(false)
                    onClose()
                },
                severity: "warning",
            })
        } else {
            setHasOngoingModifications(false)
            onClose()
        }
    }, [hasOngoingModifications, onClose, setAlert, t])

    return <Modal
        id="verticalSign-edit-modal"
        className={className}
        open={open}
        onClose={handleClose}
    >
        <div>
            <VerticalSignDetailsEditContextProvider>
                <VerticalSignDetailsEditModalContent onClose={handleClose} onChange={setHasOngoingModifications} {...otherProps} />
            </VerticalSignDetailsEditContextProvider>
        </div>
    </Modal>
}

VerticalSignDetailsEditModal.propTypes = {
    className: PropTypes.string,
    signId: PropTypes.string.isRequired,
    poleId: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
}

const VerticalSignDetailsEditModalContent = (props) => {

    const {
        signId,
        poleId,
        onClose,
        onChange,
    } = props

    const { typeSelectionDisplayed, onBack } = useVerticalSignDetailsEditStateContext()

    const { t } = useTranslation(I18N_NAMESPACE)
    const isMobile = useIsMobileDevice()

    return <Styled.Box isMobile={isMobile}>
        <Styled.TitleContainer>
            {typeSelectionDisplayed && <Styled.BackButton color="objects/object-black" onClick={() => onBack?.()}>
                <ArrowBackIcon color="objects/object-black" />
            </Styled.BackButton>}
            <Styled.Title variant="H1">{!typeSelectionDisplayed ? t(I18N_NAMESPACE, "containers.verticalSignDetailsForm.title") : t(I18N_NAMESPACE, "containers.verticalSignDetailsForm.type")}</Styled.Title>
        </Styled.TitleContainer>
        <Styled.IconButton id="close-button" onClick={onClose}>
            <Styled.CloseIcon />
        </Styled.IconButton>
        <Styled.VerticalSignDetailsFormPost close={onClose} onChange={onChange} signId={signId} poleId={poleId} />
    </Styled.Box>
}

VerticalSignDetailsEditModalContent.propTypes = {
    signId: PropTypes.string.isRequired,
    poleId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
}
