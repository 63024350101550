import {
    ListItemButton as UiListItemButton,
    styled,
} from "@mui/material"

import { ArrowRightIcon } from "@l2r-front/l2r-icons"

const ICON_SIZE = 36

export const ListItemButton = styled(UiListItemButton)(({ theme }) => {
    return ({
        backgroundColor: `${theme.palette["surfaces/surface-primary"].main} !important`,
        display: "flex",
        height: "fit-content",
        padding: theme.spacing(2, 0),
        width: "100%",

        "&:hover": {
            backgroundColor: `${theme.palette.action.hover} !important`,
        },
    })
})

export const Image = styled("img")(({ theme }) => ({
    marginRight: theme.spacing(6),
    marginTop: -6,
    marginBottom: -6,
    height: ICON_SIZE,
    width: ICON_SIZE,
    objectFit: "contain",
}))

export const ArrowIcon = styled(ArrowRightIcon)(({ theme }) => ({
    color: theme.palette["objects/object-black"].main,
    marginLeft: "auto",
}))