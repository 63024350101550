import { useParams } from "react-router-dom"

import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useIsMobileDevice } from "@l2r-front/l2r-ui"

import { TAGS_FILTER } from "../../constants/filters"
import * as Styled from "./VerticalSignsSidebarComponents.styled"

export function VerticalSignsSidebarComponents(props) {

    const { handleChangeTypeFilter, toggleFilter } = props

    const isMobile = useIsMobileDevice()
    const { code, road } = useParams()

    return <>
        {isMobile && <Styled.MobileFilterButton enabledFilters={[TAGS_FILTER]} onClick={toggleFilter} />}
        {road && <Styled.RoadDetailInfo isMobile={isMobile} road={road} />}
        <Styled.VerticalSignsGrading isMobile={isMobile} />
        <Styled.VerticalSignTypeList
            baseVerticalSignTypeCode={code}
            isMobile={isMobile}
            onChangeFilter={handleChangeTypeFilter}
        />
    </>
}

VerticalSignsSidebarComponents.propTypes = {
    handleChangeTypeFilter: PropTypes.func,
    toggleFilter: PropTypes.func,
}