import { useMemo } from "react"

import { Formik, Form } from "formik"

import { useMyUserContext } from "@l2r-front/l2r-auth"
import { useTranslation } from "@l2r-front/l2r-i18n"
import { SearchIcon } from "@l2r-front/l2r-icons"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useMediaQuery, useTheme } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { useProjectStateContext } from "../../../../common/contexts/ProjectContext"
import { useSvraiStateContext } from "../../../../common/contexts/SvraiContext"
import * as Styled from "./IncidentFiltersform.styled"

const defaultFormValues = {
    tags: [],
    publicationStatus: null,
    status: null,
}

export const IncidentFiltersForm = (props) => {

    const {
        activeFilters,
        className,
        close,
        onSubmit,
        resetFilters,
    } = props

    const { projectTags: tags } = useProjectStateContext()
    const { user } = useMyUserContext()
    const { t } = useTranslation([I18N_NAMESPACE])
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))
    const { initialFilters } = useSvraiStateContext()

    const displayedTags = useMemo(() => {
        if (tags) {
            return tags.sort()
        }
    }, [tags])
    const initialValues = useMemo(() => {
        return ({
            ...activeFilters,
            tags: activeFilters.tags || [],
        })
    }, [activeFilters])

    return <Formik
        className={className}
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={onSubmit}
    >
        {({ resetForm, setFieldValue }) => <Form>
            <Styled.InputsWrapper>
                <Styled.DateWrapper>
                    <Styled.PeriodFilter id="date-filter" />
                    <Styled.HoursFilter id="hour-filter" />
                </Styled.DateWrapper>
                <Styled.ReportStatusFilter id="report-status-filters-select" />
                <Styled.IncidentTypeFilter id="type-filters-select" />
                {displayedTags && <Styled.TagFilter
                    id="tags-filters-select"
                    disableClearable
                    label={t(I18N_NAMESPACE, "containers.incidentFilters.inputs.tags")}
                    name="tags"
                    options={displayedTags}
                    setFieldValue={setFieldValue}
                    defaultValue={initialValues?.tags}
                    popupIcon={<SearchIcon />}
                />}
                {user?.isStaff && <Styled.IncidentPublicationToggleGroup fullWidth small={isSmallScreen} />}
            </Styled.InputsWrapper>
            <Styled.Line small={isSmallScreen} />
            <Styled.ButtonsWrapper>
                <Styled.Button
                    id="reset-filters-button"
                    color="error"
                    onClick={() => {
                        resetForm({ values: { ...defaultFormValues, ...initialFilters, tags: [] } })
                        const urlParams = resetFilters()
                        close(urlParams)
                    }}
                    variant="text"
                >
                    {isSmallScreen ? t(I18N_NAMESPACE, "containers.incidentFilters.buttons.delete%small")
                        : t(I18N_NAMESPACE, "containers.incidentFilters.buttons.delete")}
                </Styled.Button>
                <Styled.Wrapper>
                    <Styled.Button
                        id="cancel-filters-button"
                        color="cta-bg/cta-bg-primary"
                        onClick={() => {
                            resetForm({ values: initialValues, ...initialFilters, tags: [] })
                            close()
                        }}
                        variant="text"
                    >
                        {t(I18N_NAMESPACE, "containers.incidentFilters.buttons.cancel")}
                    </Styled.Button>
                    <Styled.SubmitButton
                        id="submit-filters-button"
                        color="cta-bg/cta-bg-primary"
                        type="submit"
                        variant="contained"
                    >
                        {t(I18N_NAMESPACE, "containers.incidentFilters.buttons.confirm")}
                    </Styled.SubmitButton>
                </Styled.Wrapper>
            </Styled.ButtonsWrapper>
        </Form>}

    </Formik>
}

IncidentFiltersForm.propTypes = {
    filters: PropTypes.shape({
        tags: PropTypes.arrayOf(PropTypes.string),
    }),
    className: PropTypes.string,
    formData: PropTypes.shape({
        tags: PropTypes.arrayOf(PropTypes.string),
    }),
    onSubmit: PropTypes.func.isRequired,
    resetFilters: PropTypes.func.isRequired,
}