import { useQueryClient } from "@tanstack/react-query"

import { l2rApiAxiosInstance as axiosInstance } from "@l2r-front/l2r-query"

import { verticalSigningQueryKeys } from "../queries/verticalSigning/queryKeys"
import { useVerticalSigningMutation } from "./useVerticalSigningMutation"

export function useVerticalSignPoleUpdate(
    poleId,
    queryParameters,
    config = {},
) {
    const url = `vertical_sign_poles/${poleId}/`
    const queryClient = useQueryClient()

    return useVerticalSigningMutation(
        url,
        axiosInstance.patch,
        {
            ...queryParameters,
        },
        {
            ...config,
            onSuccess: (data) => {
                queryClient.invalidateQueries({ queryKey: verticalSigningQueryKeys.pole(poleId) })
                queryClient.invalidateQueries({ queryKey: verticalSigningQueryKeys.layer() })
                config.onSuccess?.(data)
            },
        },
    )
}