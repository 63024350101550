import { useCallback } from "react"

import { useTheme } from "@emotion/react"
import { Autocomplete } from "@mui/material"
import { useField, useFormikContext } from "formik"

export const FormikAutoComplete = (props) => {

    const {
        onChange,
        ...autoCompleteProps
    } = props

    const [fieldProps, metaProps] = useField(props)
    const { setStatus, setFieldValue } = useFormikContext()
    const theme = useTheme()

    const handleChange = useCallback((_, value) => {
        setFieldValue(props.name, value)
        setStatus()
        onChange?.(value)
    }, [props, onChange, setFieldValue, setStatus])

    return <Autocomplete
        {...autoCompleteProps}
        {...fieldProps}
        {...((metaProps.error && metaProps.touched) ? {
            error: true,
            helperText: metaProps.error,
        } : {})}
        onChange={handleChange}
        slotProps={{
            paper: {
                elevation: 2,
                sx: {
                    borderRadius: theme.spacing(3),
                    marginTop: theme.spacing(1),
                },
            },
        }}
    />
}