import { InputLabel as MuiInputLabel } from "@mui/material"
import { styled } from "@mui/material/styles"
import MuiTextField from "@mui/material/TextField"

export const TextField = styled(MuiTextField)(({ theme, ...props }) => ({
    display: props.type === "hidden" ? "none" : props.display,
    margin: 0,

    "&.MuiFormControl-root": {
        backgroundColor: "transparent",
    },

    "& > .MuiOutlinedInput-root": {
        backgroundColor: theme.palette["cta-bg/cta-bg-invert"].main,
        borderRadius: theme.spacing(3),
    },
}))

export const Wrapper = styled("div")({
    display: "flex",
    flexDirection: "column",
})

export const InputLabel = styled(MuiInputLabel)(({ theme, typography }) => ({
    marginBottom: theme.spacing(1),
    color: theme.palette["texts/text-primary"].main,
    fontSize: theme.typography[typography].fontSize,
    fontWeight: theme.typography[typography].fontWeight,
    textTransform: theme.typography[typography]?.textTransform,
}))