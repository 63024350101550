import { createContext } from "react"

export const initialState = {
    onBack: null,
    typeSelectionDisplayed: false,
}

const initialDispatch = {}

export const VerticalSignDetailsEditStateContext = createContext(initialState)
export const VerticalSignDetailsEditDispatchContext = createContext(initialDispatch)