import { Box, Typography, styled } from "@mui/material"

export const ButtonWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    margin: theme.spacing(5, 0, 5, 0),
    justifyContent: "space-around",
}))

export const Title = styled(Typography)(({ theme }) => ({
    margin: theme.spacing(7, 0, 5, 0),
    textAlign: "left",
    display: "inline-block",
    width: "100%",
}))

export const Text = styled(Title)(({ theme }) => ({
    margin: theme.spacing(0, 0, 5, 0),
    textAlign: "left",
    whiteSpace: "pre-line",
}))

export const Wrapper = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(8),
    left: "50%",
    p: 4,
    padding: theme.spacing(0, 7, 0, 7),
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: 465,
    maxWidth: "90%",
}))