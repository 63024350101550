import { styled } from "@l2r-front/l2r-ui"

import { RoadworkQuarterBudget as L2rRoadworkQuarterBudget } from "../RoadworkQuarterBudget/RoadworkQuarterBudget"

export const RoadworkQuartersBudgetWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    height: "fit-content",
    margin: theme.spacing(0, 8),
}))

export const RoadworkQuarterBudget = styled(L2rRoadworkQuarterBudget)(({ theme }) => ({
    "&:not(:last-child)": {
        marginRight: theme.spacing(2),
    },
    alignSelf: "flex-end",
}))