import {
    styled,
    styledForMobile,
    Typography,
    SIDEBAR_SPACING_MOBILE,
    SIDEBAR_WIDTH,
} from "@l2r-front/l2r-ui"

export const PanelContent = styled("div")(() => ({
    alignItems: "center",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    overflow: "hidden",
    width: "100%",
}))

export const KpiContainer = styledForMobile("div")(({ theme, isMobile }) => ({
    backgroundColor: theme.palette["surfaces/surface-clair"].main,
    columnGap: theme.spacing(14),
    display: isMobile ? "flex" : "grid ",
    flexDirection: "column",
    gridTemplateColumns: !isMobile && "repeat(2, 2fr)",
    height: "100%",
    padding: isMobile ? theme.spacing(SIDEBAR_SPACING_MOBILE, SIDEBAR_SPACING_MOBILE, 0, SIDEBAR_SPACING_MOBILE) : theme.spacing(14),
    rowGap: !isMobile && theme.spacing(14),
    width: "100%",
    overflowY: !isMobile && "auto",
}))

export const NetworkName = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    padding: theme.spacing(10, 10, 0, 10),
}))

export const Wrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 8, 4),
}))

export const NetworkLength = styled(Typography)(({ theme }) => ({
    padding: theme.spacing(2),
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "16px",
    fontWeight: "bold",
}))

export const Sidebar = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette["surfaces/surface-variation"].main,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: SIDEBAR_WIDTH,
}))

export const Scrollable = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    height: "fit-content",
    paddingBottom: theme.spacing(6),
    overflowY: "auto",
    width: "100%",
}))

export const PanelWrapper = styled("div")(({ theme }) => ({
    overflowY: "auto",
    height: "100%",
    width: "100%",
    padding: theme.spacing(10),
}))